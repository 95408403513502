<template>
  <Home msg="Atul Dabhi"/>
</template>

<script>
import Home from './components/Home.vue'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
#app {
  /*
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  */
}

html {
    background: #e6e9e9;
    background-image: linear-gradient(270deg, rgb(230, 233, 233) 0%, rgb(216, 221, 221) 100%);
    -webkit-font-smoothing: antialiased;
    height: 100%;
}

body {
    background: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.06);
    color: #545454;
    font-family: 'Roboto Slab', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: clamp(1em, 1.3vw, 2em);
    margin: 0 auto;
    padding: 0;
    height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    line-height: 1.3;
    color: #fff;
    /*font-family: 'Courgette', cursive, "Helvetica Neue", Helvetica, Arial, sans-serif;*/
}

.designation {
  font-size: 0.4em;
}
h1 {
  line-height: 0.7;
}

h2 {
    margin-top: 1.3em;
}

a {
    text-decoration: none;
    color: #ffdd05;
}

a:hover {
    color: #90ff00;
}

b,
strong {
    font-weight: 600;
}

samp {
    display: none;
}

@keyframes colorize {
    0% {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
    }
    100% {
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
    }
}

.container {
    background-color: #1a003c;
    text-align: center;
    width: 100%;
    min-height: 100%;
    display: table;
    color: #ecddff;
    flex-direction: row;
    flex-flow: row;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
}

.intro {
    max-width: 80%;
    margin: 0 auto;
}

.intro img.atuldabhi-logo {
    width: 50px;
}

.mid-content {
    width: 100%;
}

.brands img {
    border-radius: 6px;
    display: inline;
    width: 40%;
    max-width: 300px;
}

.brands h2 {
    margin-top: 0.6em;
}

.left {
    text-align: left;
}

.detailed-bio {
    display: flex;
}

.two-col-large {
    width: 50%;
    margin: 20px 50px;
}

@media only screen and (max-width: 999px) {
    .detailed-bio {
        display: block;
    }
    .two-col-large {
        width: 100%;
        margin: 0;
    }
    .mobile-center {
        text-align: center;
    }
    .brands {
        text-align: center;
    }
    .brands img {
        width: 48%;
    }
}

summary {
    padding: 1em;
    background-color: #ffdd05;
    color: #1a003c;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    transition: background-color .3s;
}

summary:hover {
    background-color: #90ff00;
}

.summary-details {
    padding-top: 20px;
}

details[open] summary {
    background-color: #90ff00;
}

details[open] summary~* {
    animation: sweep .5s ease-in-out;
}

@keyframes sweep {
    from {
        opacity: 0;
        margin-top: -10px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}

.certificates { border-top:1px #ffffff solid; border-bottom:1px #ffffff solid; text-align: left;}
.largimg {border-radius: 6px; width: 100%; max-width: 500px;}
</style>
