<template>
  <div class="container">
        <div class="intro">
            <div class="mid-content left">
                <div class="mobile-center">
                    <img src="../assets/images/atuldabhi-logo.svg" alt="Atul Dabhi" class="atuldabhi-logo" />
                    <h1>Atul Dabhi<br> <span class="designation">CX/UX/UI/Product Designer / front-end developer / Product manager</span></h1>
                    <p>I'm working with <a href="http://neuerenergy.com" rel="noreferrer" target="_blank">NeuerEnergy</a> as a Product Experience Manager in London, UK.</p>
                </div>
                <div class="detailed-bio">
                    <div class="two-col-large">
                        <p class="left">I'm a CX/UX/UI designer, Product designer, front-end developer, and product manager with a technical background in Master of Computer Applications - MCA. With my passion for design and ethics for the product user, I always found myself solving CX/UX problems, product design, code for front-end, managing team, and helping business. My technical background and experience help me to take important design decisions, communicate effectively, and speed up the process. During my career, I work with telecom, entertainment, textiles, energy, governance, and healthcare industries to design and improve their products and services. I have worked on marketing websites, B-to-B and B-to-C web products, and mobile/tablet apps.</p>
                        <details id="letsTalk">
                            <summary class="inverted">Let's talk</summary>
                            <div class="summary-details">
                                <a href="https://linkedin.com/in/atuldabhi" rel="noreferrer" target="_blank">LinkedIn</a> is the best way to stay connected and I'm always active to reply there, send me a message.
                            </div>
                        </details>
                    </div>
                    <div class="two-col-large">
                        <div class="brands">
                            <h2>Brands I worked with</h2>
                            <div class="brand-icon inverted">
                                <img src="../assets/images/brands/neuerenergy.jpg" alt="NeuerEnergy"> <img src="../assets/images/brands/aqovia.jpg" alt="Aqovia"> <img src="../assets/images/brands/eshare.jpg" alt="eShare"> <img src="../assets/images/brands/picturehouse.jpg" alt="PictureHouse"> <img src="../assets/images/brands/panamax.jpg" alt="Panamax">
                            </div>
                        </div>
                        <p class="left">I'm proud to be a part of NeuerEnergy product team. NeuerEnergy helps business and governments to identify their science-based carbon footprint and provides tools to analyse technologies to achieve Net zero goals. It is an innovative product and first product that provides tools for end-to-end net zero journey - Plan, procure, and manage.</p>
                        <p>I work with product users, stakeholders from cross departments, and developers. I came across many challenging problems and get through by communication with users and stakeholders. My problem-solving skill and experience helped in the situation where the user doesn't have any clue and can't provide proper inputs to solve the problem.</p>
                        <p>Ethically I feel prouder as NeuerEnergy is helping to reduce CO<sub>2</sub> emissions and make the world greener and sustainable.</p>
                        <p>I would say my previous experience working on a governance and board management products at <a href="https://eshare.net/" target="_blank" rel="nofollow">eShare</a>, A <a href="https://www.diligent.com/en-gb/" target="_blank" rel="nofollow">Diligent</a> Brand, is continue as second inning. At eShare we helped directors with better governance and at NeuerEnergy we are helping them to achieve net zero sustainability targets to lead their organisations to greater success.</p>
                    </div>
                </div>
            </div>
            <div class="certificates">
                <div class="detailed-bio">
                    <div class="two-col-large">
                        <p class="left">I always love to explore and learn new things. Sometimes I look back in the past and sometimes I try to learn where things are heading.</p>
                        <p class="left">Currently, I'm exploring and learning things with father of UX, Don Norman, on <a href="https://www.interaction-design.org/" target="_blank">Interaction Design Foundation - IxDF</a>.</p>
                        <p class="left"><img src="../assets/images/Design-is-intelligence-made-visible.jpg" alt="Design is intelligence made visible" class="largimg"></p>
                    </div>
                    <div class="two-col-large brands">
                        <H2>IxDF Certificates</H2>
                        <a href="https://www.interaction-design.org/members/atulkumar-dabhi/certificate/masterclass/mcc_e7314126916e4938853dc432783fe3b9" target="_blank"><img src="../assets/images/certificates/masterclass-certificate-exclusive-design-for-a-better-world-a-discussion-with-don-norman.jpg" alt="Design for a better world a discussion with don norman."></a> <a href="https://www.interaction-design.org/members/atulkumar-dabhi/certificate/masterclass/mcc_b8d50234ec114e819cf6951705b0eb5a" target="_blank"><img src="../assets/images/certificates/masterclass-certificate-ux-writing-how-to-use-words-as-a-design-power-tool.jpg" alt="UX writing how to use words as a design power tool."></a> <a href="https://www.interaction-design.org/members/atulkumar-dabhi/certificate/masterclass/mcc_1474fe27f08e4c01967e283453df83c5" target="_blank"><img src="../assets/images/certificates/masterclass-certificate-21st-century-design-with-don-norman.jpg" alt="21st century design with don norman."></a> <a href="https://www.interaction-design.org/members/atulkumar-dabhi/certificate/masterclass/mcc_6ab118cbcf284840991dfda7d4b25954" target="_blank"><img src="../assets/images/certificates/masterclass-certificate-game-ux-foundations-starter-edition.jpg" alt="Game UX foundations starter edition."></a>
                    </div>
                </div>
            </div>
            <div class="brands">
                <h3>Let's connect</h3>
            </div>
            <!--
            <div class="LI-profile-badge" data-version="v1" data-size="large" data-locale="en_US" data-type="vertical" data-theme="light" data-vanity="atuldabhi"><a class="LI-simple-link" href='https://uk.linkedin.com/in/atuldabhi?trk=profile-badge'>Atulkumar Dabhi</a></div>
            <script type="text/javascript" src="https://platform.linkedin.com/badges/js/profile.js" async defer></script>
            <div class="LI-profile-badge inverted" data-version="v1" data-size="large" data-locale="en_US" data-type="vertical" data-theme="dark" data-vanity="atuldabhi"><a class="LI-simple-link" href='https://uk.linkedin.com/in/atuldabhi?trk=profile-badge'>Atulkumar Dabhi</a></div>
            -->
            <div class="badge-base LI-profile-badge" data-locale="en_US" data-size="large" data-theme="dark" data-type="VERTICAL" data-vanity="atuldabhi" data-version="v1"><a class="badge-base__link LI-simple-link" href="https://uk.linkedin.com/in/atuldabhi?trk=profile-badge">LinkedIn</a></div>

        </div>

    </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
img {margin-bottom: 5px;}
</style>
